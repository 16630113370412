
import { Options, Vue } from "vue-class-component";
import StickyPage from "@/components/sticky-page.vue";
import StickyActionSheet from "@/components/sticky/action-sheet.vue";
import { LogService } from "@/services/logService";
import { SettingsFactory } from "@/shared/config";
@Options({
  name: "Subscription",
  props: {},
  components: {
    StickyPage,
    StickyActionSheet
  },
})
export default class Subscription extends Vue {
  private paypalLoaded = false;
  private showPaypalMonthly = false;
  private ls = new LogService();  
  private config = new SettingsFactory().getSettings();
  
  public async created() {
    this.appendPaypalScript().then(
      () => {
        this.paypalLoaded = true;
        //TODO:this.initPayButtons();
      },
      (err) => {
        this.ls.error(err);
        this.$app.snack({ msg: "Error connecting to Paypal", type: "error" });
      }
    );
  }
  public async mounted() {
    window.ww.theme.initAll();
  }

  public async monthly() {
    this.$app.toast({ msg: "monthly!" });
    console.log("monthly");
  }

  public initPayButtons() {
    window.paypal
      .Buttons({
        style: {
          shape: "pill",
          color: "blue",
          layout: "vertical",
          label: "subscribe",
        },
        createSubscription: (data, actions) => {
          return actions.subscription.create({
            plan_id: this.config.paypal.monthlyPlanId,
          });
        },
        onApprove: async (data, actions) => {
          alert(data.subscriptionID);
          this.$app.snack({
            msg: `Subscribed!`,
            type: "success",
          });
        },
        onCancel: (data) => {
          // Show a cancel page, or return to cart
          this.ls.info("paypal subscription signup cancel");
          this.$app.snack({
            msg:
              "Thanks for considering subscribing! Drop us a line telling how we can earn your business.",
            type: "info",
          });
        },
        onError: (err) => {
          this.ls.error(err);
          this.$app.snack({
            type: "error",
            msg:
              "There was an error creating your subscription. Please try again later",
          });
        },
      })
      .render("#paypal-button-container");
  }
  public appendPaypalScript(): Promise<void> {
    const paypalLoad = (window as any).paypal;
    if (!paypalLoad) {
      return new Promise<void>((resolve, reject) => {
        const scriptEl = window.document.createElement("script");
        scriptEl.async = true;
        scriptEl.src = `https://www.paypal.com/sdk/js?client-id=${this.config.paypal.clientId}&vault=true&intent=subscription`;
        scriptEl.setAttribute("data-sdk-integration-source", "button-factory");
        scriptEl.onload = () => resolve();
        scriptEl.onerror = (error) =>
          reject({ message: "Error loading Paypal library", error: error });
        window.document.head.appendChild(scriptEl);
      });
    }

    // paypal is already loaded.
    return Promise.resolve();
  }
}
