
import StickyPage from "@/components/sticky-page.vue";
import { Options, Vue } from "vue-class-component";
import { WWUser, DefaultUser, UUserService } from "@/shared/user";
import router from "@/router";
import {
  faMedal,
  faCertificate,
  faAward,
} from "@adroitcode/pro-solid-svg-icons";
import UserService from "@/services/userService";
// @ is an alias to /src
@Options({
  name:"ProfileView",
  components: {
    StickyPage,
  },
})
export default class ProfileView extends Vue {
  private user = UserService;
  private medal = faMedal;
  private cert = faCertificate;
  private award = faAward;

  public get showHomeAd() {
    return true;
  }
  public async mounted() {
    window.ww.theme.initAll();
  }
}
