<template>
  <sticky-page>
    <div class="card card-style">
      <div class="content mt-3">
        <div class="d-flex">
          <div class="align-self-center">
            <p class="mb-n1 font-400 font-12 opacity-60">Welcome back,</p>
            <h1 class="font-30">{{ user.displayName }}</h1>
          </div>
          <div class="align-self-center ml-auto">
            <a href="#"
              ><img
                :src="user.avatarUri || '/images/pictures/faces/1s.png'"
                width="50"
                class="rounded-circle shadow-xl"
            /></a>
          </div>
        </div>

        <div class="divider mt-3 mb-3"></div>

        <div class="d-flex">
          <div class="mr-auto">
            <h1 class="mb-n2">24</h1>
            <p class="font-11">Completed</p>
          </div>
          <div class="mr-auto ml-auto">
            <h1 class="mb-n2">35</h1>
            <p class="font-11">Saved</p>
          </div>
          <div class="ml-auto">
            <h1 class="mb-n2">76</h1>
            <p class="font-11">In Progress</p>
          </div>
        </div>
      </div>
    </div>
    <div class="card card-style">
      <div class="content">
        <h3>Achievements</h3>
        <p>Your work deserves recognition! Here are some awesome badges!</p>
        <div class="d-flex text-center">
          <div class="m-auto">
            <span class="icon icon-xxl bg-yellow1-dark rounded-m"
              ><fa icon="star" class="font-24"></fa
            ></span>
            <p class="font-12 line-height-xs pt-2 font-500">
              Rising <br />
              Stars
            </p>
          </div>
          <div class="m-auto">
            <span class="icon icon-xxl bg-green1-dark rounded-m"
              ><fa icon="eye" class="font-24"></fa
            ></span>
            <p class="font-12 line-height-xs pt-2 font-500">
              Fast <br />
              Reader
            </p>
          </div>
          <div class="m-auto">
            <span class="icon icon-xxl bg-red2-dark rounded-m"
              ><fa icon="trophy" class="font-24"></fa
            ></span>
            <p class="font-12 line-height-xs pt-2 font-500">
              Course <br />
              Master
            </p>
          </div>
        </div>
      </div>
    </div>

    <div class="card card-style">
      <div class="content">
        <h3>Bookmarked</h3>
        <p>Your unfinished courses. Continue learning before they expire.</p>

        <a href="#" class="d-flex pb-4">
          <div class="align-self-center">
            <img src="images/pictures/14s.jpg" class="rounded-sm" width="60" />
          </div>
          <div class="align-self-center pl-3">
            <h5 class="mb-n1">Mobile PWA's</h5>
            <p class="font-600 opacity-40 mb-0">1 hour, 24 minutes</p>
            <div class="progress mt-2" style="height: 2px; width: 130px">
              <div
                class="progress-bar border-0 bg-red1-dark text-left pl-2"
                role="progressbar"
                style="width: 20%"
                aria-valuenow="10"
                aria-valuemin="0"
                aria-valuemax="100"
              ></div>
            </div>
          </div>
          <div class="align-self-center ml-auto">
            <fa icon="play-circle" class="font-24 color-highlight"></fa>
          </div>
        </a>

        <a href="#" class="d-flex pb-4">
          <div class="align-self-center">
            <img src="images/pictures/16s.jpg" class="rounded-sm" width="60" />
          </div>
          <div class="align-self-center pl-3">
            <h5 class="mb-n1">Hybrid Apps</h5>
            <p class="font-600 opacity-40 mb-0">5 hour, 32 minutes</p>
            <div class="progress mt-2" style="height: 2px; width: 130px">
              <div
                class="progress-bar border-0 bg-green1-dark text-left pl-2"
                role="progressbar"
                style="width: 80%"
                aria-valuenow="10"
                aria-valuemin="0"
                aria-valuemax="100"
              ></div>
            </div>
          </div>
          <div class="align-self-center ml-auto">
            <fa icon="play-circle" class="font-24 color-highlight"></fa>
          </div>
        </a>
        <a href="#" class="d-flex">
          <div class="align-self-center">
            <img src="images/pictures/17s.jpg" class="rounded-sm" width="60" />
          </div>
          <div class="align-self-center pl-3">
            <h5 class="mb-n1">Optimizing Performance</h5>
            <p class="font-600 opacity-40 mb-0">2 hour, 14 minutes</p>
            <div class="progress mt-2" style="height: 2px; width: 130px">
              <div
                class="progress-bar border-0 bg-blue2-dark text-left pl-2"
                role="progressbar"
                style="width: 40%"
                aria-valuenow="10"
                aria-valuemin="0"
                aria-valuemax="100"
              ></div>
            </div>
          </div>
          <div class="align-self-center ml-auto">
            <fa icon="play-circle" class="font-24 color-highlight"></fa>
          </div>
        </a>
        <div class="divider mt-3 mb-3"></div>
        <a
          href="#"
          class="btn btn-sm btn-full rounded-sm font-800 bg-highlight text-uppercase"
          >VIEW ALL</a
        >
      </div>
    </div>
    <div class="card card-style">
      <div class="content">
        <div class="d-flex">
          <div class="align-self-center pr-3">
            <span class="fa fa-check-circle color-green1-dark fa-5x">
              <fa icon="check-circle"></fa>
            </span>
          </div>
          <div class="align-self-center">
            <h4>You're Subscribed</h4>
            <p class="line-height-s">
              Your pack will expires <strong>15th of May, 2025</strong>. It will
              renew automatically on that day.
            </p>
          </div>
        </div>
      </div>

      <!-- <div class="card card-style">
        <div class="content">
          <div class="d-flex">
            <div class="align-self-center">
              <fa icon="check-circle" class="color-green1-dark fa-4x pr-3"></fa>
            </div>
            <div class="mr-auto">
              <h2>Premium Account</h2>
              <p class="line-height-s">
                Your current subscription exprires
                <strong>March 24th, 2025.</strong>
              </p>
            </div>
          </div>
          <a
            href="#"
            class="btn btn-sm btn-full rounded-sm font-800 bg-green1-dark text-uppercase mt-4"
            >Renew Subscription</a
          >
        </div> -->
    </div>
    <div class="card card-style">
      <div class="content">
        <h1>
          Awards<i
            class="fa fa-trophy float-right font-16 pt-1 color-yellow1-dark"
            ><fa icon="trophy"
          /></i>
        </h1>
        <p>Lorem ipsum dolor sit amet, consectetur elit.</p>

        <div class="row text-center mb-0">
          <div class="col-6">
            <i class="fa fa-trophy color-yellow1-dark fa-4x mb-3"
              ><fa icon="trophy"
            /></i>
            <h6 class="text-uppercase font-700">
              Best <br />
              Photographer
            </h6>
            <p>Best Photographer on Envato Awards</p>
          </div>
          <div class="col-6">
            <i class="fa fa-certificate fa-4x color-blue2-dark mb-3"
              ><fa :icon="cert"
            /></i>
            <h6 class="text-uppercase font-700">Best<br />Gallery</h6>
            <p>Best Photo Gallery on Envato Awards</p>
          </div>
          <div class="col-12 mb-4"></div>
          <div class="col-6">
            <i class="fa fa-award color-red2-dark fa-4x mb-3"
              ><fa :icon="award"
            /></i>
            <h6 class="text-uppercase font-700">Best<br />Support</h6>
            <p>Best Customer Support on Envato Awards</p>
          </div>
          <div class="col-6">
            <i class="fa fa-medal fa-4x color-brown1-dark mb-3"
              ><fa :icon="medal"
            /></i>
            <h6 class="text-uppercase font-700">Best<br />Updates</h6>
            <p>Best Product Updates on Envato Awards</p>
          </div>
        </div>
      </div>
    </div>
  </sticky-page>
</template>

<script lang="ts">
import StickyPage from "@/components/sticky-page.vue";
import { Options, Vue } from "vue-class-component";
import { WWUser, DefaultUser, UUserService } from "@/shared/user";
import router from "@/router";
import {
  faMedal,
  faCertificate,
  faAward,
} from "@adroitcode/pro-solid-svg-icons";
import UserService from "@/services/userService";
// @ is an alias to /src
@Options({
  name:"ProfileView",
  components: {
    StickyPage,
  },
})
export default class ProfileView extends Vue {
  private user = UserService;
  private medal = faMedal;
  private cert = faCertificate;
  private award = faAward;

  public get showHomeAd() {
    return true;
  }
  public async mounted() {
    window.ww.theme.initAll();
  }
}
</script>
