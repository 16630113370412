<template>
  <sticky-page>
    <div class="card card-style">
      <div class="content">
        <div class="d-flex">
          <div class="align-self-center pr-3">
            <i class="color-green1-dark fa-5x"><fa icon="check-circle" /></i>
          </div>
          <div class="align-self-center">
            <h4>You're Subscribed</h4>
            <p class="line-height-s">
              Your subscripton expires <strong>DEV</strong>. It will renew
              automatically on that day.
            </p>
          </div>
        </div>
      </div>
    </div>

    <div class="card card-style">
      <div class="card rounded-0 bg-17 mb-0" data-card-height="150">
        <div class="card-top m-2">
          <span
            class="badge bg-highlight rounded-sm font-11 color-white float-right py-2 px-3 text-uppercase"
            >Basic</span
          >
        </div>
      </div>
      <div class="content">
        <h2>Ad Supported</h2>
        <p class="mb-3">Use basic features across unlimited projects.</p>
        <div class="row mb-0">
          <div class="col-6">
            <ul class="icon-list">
              <li>
                <i class="color-green1-dark"><fa icon="check" /></i>Unlimited
                Projects
              </li>
              <li>
                <i class="color-green1-dark"><fa icon="check" /></i>Local backup
              </li>
            </ul>
          </div>
          <div class="col-6">
            <ul class="icon-list">
              <li>
                <i class="color-red1-dark"><fa :icon="['far', 'frown']" /></i
                >Advertisements
              </li>
              <li>
                <i class="color-green1-dark"><fa icon="check" /></i>Writing
                Insights
              </li>
            </ul>
          </div>
        </div>

        <a
          href="#"
          class="btn btn-full btn-m rounded-sm bg-highlight font-800 text-uppercase"
          >Continue with Ads</a
        >
      </div>
    </div>

    <div class="card card-style">
      <div class="card rounded-0 bg-17 mb-0" data-card-height="150">
        <div class="card-top m-2">
          <span
            class="badge bg-blue2-dark rounded-sm font-11 color-white float-right py-2 px-3 text-uppercase"
            >Premium</span
          >
        </div>
      </div>
      <div class="content mb-2">
        <h2>Premium Subscription</h2>
        <p class="mb-3">
          Support app development and enjoy all the features without limits or
          ads.
        </p>
        <div class="row mb-0">
          <div class="col-6">
            <ul class="icon-list">
              <li>
                <i class="color-green1-dark"><fa icon="check" /></i>Unlimited
                Projects
              </li>
              <li>
                <i class="color-green1-dark"><fa icon="check" /></i>Cloud backup
              </li>
              <li>
                <i class="color-green1-dark"><fa icon="check" /></i>Unlimited
                devices
              </li>
            </ul>
          </div>
          <div class="col-6">
            <ul class="icon-list">
              <li>
                <i class="color-green1-dark"
                  ><fal>
                    <fa
                      :icon="['far', 'ad']"
                      transform="shrink-2"
                      class="color-red2-dark"
                    />
                    <fa :icon="['far', 'slash']" class="font-900" />
                    <!-- <fa :icon="['far', 'slash']" size="lg" :transform="{ rotate: 90 }" /> -->
                    <!-- <falt transform="grow-2" class="font-200 font-24" value="X" /> -->
                  </fal> </i
                >Enhanced Privacy
              </li>
              <li>
                <i class="color-green1-dark"><fa icon="check" /></i>Writing
                Insights
              </li>
              <li class="font-900">
                <i class="color-green1-dark"><fa icon="check" /></i>Custom
                Surveys
              </li>
            </ul>
          </div>
        </div>
        <sticky-action-sheet title="Continue Monthly" subTitle="just $1.99 a month" :show="showPaypalMonthly" @close="showPaypalMonth=false">
          <div id="paypal-button-container"></div>
        </sticky-action-sheet>
        <button
          @click="monthly"
          :disabled="!paypalLoaded"
          class="btn btn-full btn-block btn-m rounded-sm bg-blue2-dark font-800 text-uppercase"
        >
          Continue Monthly
        </button>
        <p class="mb-0 color-dark2-dark opacity-60 font-11 text-center">
          7 Day Free Trial then $1.99 / month
        </p>
      </div>
    </div>

    <!-- <div class="card card-style">
      <div class="card rounded-0 bg-17 mb-0" data-card-height="150">
        <div class="card-top m-2">
          <span
            class="badge bg-blue2-dark rounded-sm font-11 color-white float-right py-2 px-3 text-uppercase"
            >Ad Supported</span
          >
        </div>
      </div>
      <div class="content">
        <h2>Taking Notes Properly</h2>
        <p class="mb-3">
          Learn to create amazing web pages to abide to 2025's standards of Web
          Design. Carefuly catered by a sernior web developer.
        </p>
        <div class="divider mb-3"></div>
        <p class="font-11 mb-n1 float-left">3 Hour, 45 Minutes</p>
        <p class="font-11 mb-n1 float-right">60% Completed</p>
        <div class="clearfix"></div>
        <div class="progress mt-2 mb-4" style="height: 2px">
          <div
            class="progress-bar border-0 bg-blue2-dark text-left pl-2"
            role="progressbar"
            style="width: 60%"
            aria-valuenow="10"
            aria-valuemin="0"
            aria-valuemax="100"
          ></div>
        </div>

        <a
          href="#"
          class="btn btn-full btn-m rounded-sm bg-blue2-dark font-800 text-uppercase"
          >Annual Plan ${{ usdAnnualPrice }}</a
        >
      </div>
    </div> -->
  </sticky-page>
</template>
<script lang="ts">
import { Options, Vue } from "vue-class-component";
import StickyPage from "@/components/sticky-page.vue";
import StickyActionSheet from "@/components/sticky/action-sheet.vue";
import { LogService } from "@/services/logService";
import { SettingsFactory } from "@/shared/config";
@Options({
  name: "Subscription",
  props: {},
  components: {
    StickyPage,
    StickyActionSheet
  },
})
export default class Subscription extends Vue {
  private paypalLoaded = false;
  private showPaypalMonthly = false;
  private ls = new LogService();  
  private config = new SettingsFactory().getSettings();
  
  public async created() {
    this.appendPaypalScript().then(
      () => {
        this.paypalLoaded = true;
        //TODO:this.initPayButtons();
      },
      (err) => {
        this.ls.error(err);
        this.$app.snack({ msg: "Error connecting to Paypal", type: "error" });
      }
    );
  }
  public async mounted() {
    window.ww.theme.initAll();
  }

  public async monthly() {
    this.$app.toast({ msg: "monthly!" });
    console.log("monthly");
  }

  public initPayButtons() {
    window.paypal
      .Buttons({
        style: {
          shape: "pill",
          color: "blue",
          layout: "vertical",
          label: "subscribe",
        },
        createSubscription: (data, actions) => {
          return actions.subscription.create({
            plan_id: this.config.paypal.monthlyPlanId,
          });
        },
        onApprove: async (data, actions) => {
          alert(data.subscriptionID);
          this.$app.snack({
            msg: `Subscribed!`,
            type: "success",
          });
        },
        onCancel: (data) => {
          // Show a cancel page, or return to cart
          this.ls.info("paypal subscription signup cancel");
          this.$app.snack({
            msg:
              "Thanks for considering subscribing! Drop us a line telling how we can earn your business.",
            type: "info",
          });
        },
        onError: (err) => {
          this.ls.error(err);
          this.$app.snack({
            type: "error",
            msg:
              "There was an error creating your subscription. Please try again later",
          });
        },
      })
      .render("#paypal-button-container");
  }
  public appendPaypalScript(): Promise<void> {
    const paypalLoad = (window as any).paypal;
    if (!paypalLoad) {
      return new Promise<void>((resolve, reject) => {
        const scriptEl = window.document.createElement("script");
        scriptEl.async = true;
        scriptEl.src = `https://www.paypal.com/sdk/js?client-id=${this.config.paypal.clientId}&vault=true&intent=subscription`;
        scriptEl.setAttribute("data-sdk-integration-source", "button-factory");
        scriptEl.onload = () => resolve();
        scriptEl.onerror = (error) =>
          reject({ message: "Error loading Paypal library", error: error });
        window.document.head.appendChild(scriptEl);
      });
    }

    // paypal is already loaded.
    return Promise.resolve();
  }
}
</script>