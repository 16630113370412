<template>
  <teleport to="#tele-modals">
    <div
      ref="menu"
      class="menu menu-box-bottom menu-box-detached rounded-m"
      data-menu-effect="menu-over"
      data-menu-height="300"
    >
      <div class="menu-title mt-n1">
        <h1>{{ title }}</h1>
        <p class="color-theme opacity-50">{{ subTitle }}</p>
        <a href="#" class="close-menu"><i class="fa fa-times"></i></a>
      </div>
      <slot />
    </div>
  </teleport>
</template>
 <script lang="ts">import { defineComponent } from '@vue/runtime-core'

export default defineComponent({
  name: "StickyActionSheet",
  props: {
    title: {
      type: String,
      required: true,
    },
    subTitle: {
      type: String,
    },
    show: {
      type: Boolean,
      default: false,
      required: true,
    },
  },
  emits: ["close"],
  watch: {
    show: {
      handler: async function(newValue: boolean, oldValue: boolean) {
        if (!newValue) {
          return;
        }

        const menu = this.$refs.menu as HTMLElement; //because of teleport
        //Close Existing Opened Menus
        const activeMenu = document.querySelectorAll(".menu-active");
        for (let i = 0; i < activeMenu.length; i++) {
          activeMenu[i].classList.remove("menu-active");
        }
        //Open Clicked Menu

        menu.classList.add("menu-active");
        document
          .getElementsByClassName("menu-hider")[0]
          .classList.add("menu-active");
        //Check and Apply Effects
        // const menuEffect = menu.getAttribute("data-menu-effect");
        // const menuLeft = menu.classList.contains("menu-box-left");
        // const menuRight = menu.classList.contains("menu-box-right");
        // const menuTop = menu.classList.contains("menu-box-top");
        // const menuBottom = menu.classList.contains("menu-box-bottom");
        // const menuWidth = menu.offsetWidth;
        // const menuHeight = menu.offsetHeight;
      },
    },
  },
  // @Watch("show")
  // public async onChange(newValue: boolean, oldValue: boolean) {
  //   if(!newValue){
  //     return;
  //   }
  //   const menu = this.$refs.menu as HTMLElement;//because of teleport
  //    //Close Existing Opened Menus
  //   const activeMenu = document.querySelectorAll('.menu-active');
  //   for(let i=0; i < activeMenu.length; i++){activeMenu[i].classList.remove('menu-active');}
  //   //Open Clicked Menu
  //   menu.classList.add('menu-active');
  //   document.getElementsByClassName('menu-hider')[0].classList.add('menu-active');
  //   //Check and Apply Effects
  //   const menuEffect = menu.getAttribute('data-menu-effect');
  //   const menuLeft = menu.classList.contains('menu-box-left');
  //   const menuRight = menu.classList.contains('menu-box-right');
  //   const menuTop = menu.classList.contains('menu-box-top');
  //   const menuBottom = menu.classList.contains('menu-box-bottom');
  //   const menuWidth = menu.offsetWidth;
  //   const menuHeight = menu.offsetHeight;
  //   // const wrappers = document.querySelectorAll('.header, #footer-bar, .page-content')  as NodeListOf<HTMLElement>;
  //   // if(menuEffect === "menu-push"){
  //   //     const menuWidth = menu.getAttribute('data-menu-width');
  //   //     if(menuLeft){for(let i=0; i < wrappers.length; i++){wrappers[i].style.transform = "translateX("+menuWidth+"px)"}}
  //   //     if(menuRight){for(let i=0; i < wrappers.length; i++){wrappers[i].style.transform = "translateX(-"+menuWidth+"px)"}}
  //   //     if(menuBottom){for(let i=0; i < wrappers.length; i++){wrappers[i].style.transform = "translateY(-"+menuHeight+"px)"}}
  //   //     if(menuTop){for(let i=0; i < wrappers.length; i++){wrappers[i].style.transform = "translateY("+menuHeight+"px)"}}
  //   // }
  //   // if(menuEffect === "menu-parallax"){
  //   //     const menuWidth = parseInt(menu.getAttribute('data-menu-width') ||'');
  //   //     if(menuLeft){for(let i=0; i < wrappers.length; i++){wrappers[i].style.transform = "translateX("+(menuWidth/10)+"px)"}}
  //   //     if(menuRight){for(let i=0; i < wrappers.length; i++){wrappers[i].style.transform = "translateX(-"+menuWidth/10+"px)"}}
  //   //     if(menuBottom){for(let i=0; i < wrappers.length; i++){wrappers[i].style.transform = "translateY(-"+menuHeight/5+"px)"}}
  //   //     if(menuTop){for(let i=0; i < wrappers.length; i++){wrappers[i].style.transform = "translateY("+menuHeight/5+"px)"}}
  //   // }
  // }
});
</script>